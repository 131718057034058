import React, { useEffect, useRef, useState, type PropsWithChildren, type ReactNode } from "react";
import { motion, AnimatePresence } from "framer-motion";
import clsx from "clsx";

interface Props extends PropsWithChildren {
  className?: string;
  classNames?: {
    base?: string;
    headline?: string;
    content?: string;
  };
  isOpen?: boolean;
  onOpen?: (isOpen: boolean, event: React.MouseEvent) => void;
  headline: ReactNode;
  // onOpen?: (open: boolean, elem: Element | null) => void;
}

const Collapse: React.FC<Props> = ({ className, classNames, isOpen = false, headline, children, onOpen, ...props }) => {
  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  return (
    <div className={clsx("avpt-collapse transition-[height]", className, classNames?.base)} data-expanded={open} {...props}>
      <button
        type="button"
        className={clsx("headline w-full bg-transparent border-0 flex cursor-pointer text-left", classNames?.headline)}
        onClick={e => {
          setOpen(!open);
          !!onOpen && onOpen(!open, e);
        }}
      >{headline}</button>
      <AnimatePresence initial={false}>
        {open && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { height: "auto", opacity: 1 },
              collapsed: { height: 0, opacity: 0 }
            }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            style={{ overflow: "hidden" }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Collapse;