import { useTranslated } from '@i18next/utils';
import Link from '@react/widget/Link';
import clsx from 'clsx';

interface Props extends Record<string, any> {
  currentSite: Page.Site;
  data?: Page.Navigation;
}

const FooterCommon: React.FC<Props> = ({ currentSite, data, ...props }) => {
  const { t, tr } = useTranslated(currentSite.locale);
  if (!data)
    return <></>;
  return (
    <dl {...props}>
      <dt className="border-split-light border-solid border-b py-3 font-medium">
        {data.title === "Confidence Platform" ? <a href={tr(`/confidence-platform`)}>{t(data.title)}</a> : t(data.title)}
      </dt>
      {data.children?.map((_nav, index, _a) => (
        <dd key={`footer-common-${index}`} className={clsx("text-sm border-split-light border-solid", !!index && "border-t")}>
          {(_a.length > 1) && <span className="block pt-2.5 font-medium tracking-wide">{_nav.title}</span>}
          <ul className="py-2.5 grid gap-y-2.5">
            {_nav.children?.map((_item, _index) => (_item.element?.status != "disabled") && <li key={`footer-common-${index}-li-${_index}`}><Link href={tr(_item.uri)}>{_item.title}</Link></li>)}
          </ul>
        </dd>
      ))}
    </dl>
  );
};

export default FooterCommon;